import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/home";
import Footer from "./components/footer";
import Header from "./components/header";
import Auth from "./pages/auth";
import Confirm from "./pages/confirm";
import Dashboard from "./pages/dashboard";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import toast, { Toaster } from "react-hot-toast";

export default function App() {
    const [isReady, setReady] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            setUser(user);
            setReady(true);
        });
    });

    return isReady ? (
        <BrowserRouter>
            {/* <Header user={user} /> */}
            <Routes>
                {/* <Route
                    path="/"
                    element={user ? <Dashboard user={user} /> : <Home />}
                /> */}
                <Route path="/" element={<Home />} />
                {/* <Route path="/auth" element={<Auth />} /> */}
                {/* <Route path="/confirm" element={<Confirm />} /> */}
                <Route path="*" element={<Navigate to={"/"} replace />} />
            </Routes>
            <Footer />
            {/* <Toaster /> */}
        </BrowserRouter>
    ) : null;
}
